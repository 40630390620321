<template>
  <component :is="'PageWithColoredCenteredBox'">
    <div class="page-distance">
      <div class="intro" v-html="$t('page-distance.set.intro')"></div>
      <div class="select-distances-container margin-top-l">

        <div class="select-distance"
             v-on:click="setDistance('40cm')"
             :class="getDistance() === 0 ? 'selected' : ''">
          <div class="top-selected-info">{{ $t('page-distance.select-distance.selected') }}</div>
          <p v-html="$t('page-distance.select-distance.title-40-cm')"></p>
          <img alt="Distance 40cm" class="margin-top-m img-responsive" :src="getDeviceImage('40cm')"/>
          <button
              class="btn small-btn margin-top-l"
              v-on:click="this.$changePageWithAnalyticsStep(this.$store, 'PageMeasure40cm')">
            {{
              getDistance() === 0 ?
                  $t('page-distance.select-distance.button-text-40-cm-selected') :
                  $t('page-distance.select-distance.button-text-40-cm')
            }}
          </button>
        </div>

        <div class="select-distance"
             v-on:click="setDistance('2m')"
             :class="getDistance() === 1 ? 'selected' : ''">
          <div class="top-selected-info">{{ $t('page-distance.select-distance.selected') }}</div>
          <p v-html="$t('page-distance.select-distance.title-2-m')"></p>
          <img alt="Distance 2m" class="margin-top-m"  :src="getDeviceImage('2m')"/>
          <button
              class="btn small-btn margin-top-l"
              v-on:click="this.$changePageWithAnalyticsStep(this.$store, 'Page2Metres')">
            {{
              getDistance() === 1 ?
                  $t('page-distance.select-distance.button-text-2-m-selected') :
                  $t('page-distance.select-distance.button-text-2-m')
            }}
          </button>
        </div>

      </div>
    </div>
  </component>
</template>

<script>
import distanceInfo40cmImage from '@/assets/distance-info-40-cm.png';
import distanceInfo2mImage from '@/assets/distance-info-2-m.png';
import ringsInfoImage from '@/assets/rings-info.png';
import direction from '@/assets/img/direction.svg';
import coverLeft from '@/assets/img/cover-left.svg';
import coverRight from '@/assets/img/cover-right.svg';
import distance2m from '@/assets/img/distance-2m.svg';
import distance40cm from '@/assets/img/distance-40cm.svg';
import tablet40cm from '@/assets/img/tablet-40cm.svg';
import tablet2m from '@/assets/img/tablet-2m.svg';
import smartphone2m from '@/assets/img/smartphone-2m.svg';
import smartphone40cm from '@/assets/img/smartphone-40cm.svg';


import PageWithColoredCenteredBox from "@/components/pages/generic/PageWithColoredCenteredBox";
import {getDeviceType} from "@/utils/utils";
import {deviceTypes} from "@/constants";

export default {
  components: {PageWithColoredCenteredBox},
  data() {
    const distances = Object.fromEntries(
        Object.keys(this.$store.getters.getTestConfiguration).map((key) => [key, this.$store.getters.getTestConfiguration[key].index])
    );
    // {"40cm": 0, "2m": 1}

    return {
      distances: distances,
      distanceIsSet: false,
      distanceInfoImage: null,
      ringsInfoImage: ringsInfoImage,
      direction: direction,
      coverLeft: coverLeft,
      coverRight: coverRight,
      distance2m: distance2m,
      distance40cm: distance40cm,
    };
  },
  methods: {
    setDistance(distance) {
      this.$store.commit('setDistance', this.distances[distance]);
      this.distanceIsSet = true;
      this.distanceInfoImage = distance === '40cm' ? distanceInfo40cmImage : distanceInfo2mImage;
    },
    getDistance() {
      return this.$store.state.distance;
    },
    getDeviceImage(distance){
      if(distance==="40cm"){
          switch (getDeviceType()) {
            case deviceTypes.MOBILE:
              return smartphone40cm
            case deviceTypes.TABLET:
              return tablet40cm
            default:
              return distance40cm
          }
      } else {
        switch (getDeviceType()) {
            case deviceTypes.MOBILE:
              return smartphone2m
            case deviceTypes.TABLET:
              return tablet2m
            default:
              return distance2m
          }
      }

    }

  },
};
</script>
